import gql from "graphql-tag";
import Cookies from "universal-cookie";

export class Authentication {
	public cookies: Cookies = new Cookies();

	constructor(p: { cookies: string }) {
		this.cookies = new Cookies(p?.cookies);
	}

	getSessionToken = () => {
		return this.cookies.get("SESSION_TOKEN");
	};
	setSessionToken = (sessionToken: string) => {
		return this.cookies.set("SESSION_TOKEN", sessionToken);
	};
	removeSessionToken = () => {
		return this.cookies.remove("SESSION_TOKEN");
	};

	checkingSession: boolean = false;
	sessionHasBeenChecked: boolean = false;
}
