import {
	createClient as _createClient,
	dedupExchange,
	fetchExchange,
	cacheExchange,
	errorExchange,
} from "@urql/core";
import { retryExchange } from "@urql/exchange-retry";
import { offlineExchange } from "@urql/exchange-graphcache";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { IntrospectionQuery } from "graphql";
import schema from "./introspection.json";
import { withUrqlClient, SSRExchange } from "next-urql";
import { Authentication } from "./authentication";
import { NextPageContext } from "next";
import { CombinedError, Operation } from "urql";

const url = process.env.GRAPH_URL;

export default withUrqlClient(
	//@ts-ignore
	(ssrExchange: SSRExchange, ctx?: NextPageContext) => {
		let cookie = ctx?.req?.headers.cookie;
		if (!cookie && typeof document !== "undefined") {
			cookie = document.cookie;
		}

		const authentication = new Authentication({ cookies: cookie || "" });
		return {
			suspense: false,
			// FIXME: need to remove any
			url: url as any,

			fetchOptions: () => {
				const headers: HeadersInit = {};
				if (authentication.getSessionToken()) {
					headers[
						"Authorization"
					] = `Bearer ${authentication.getSessionToken()}`;
				}
				return {
					headers,
				};
			},

			exchanges: [
				dedupExchange,
				retryExchange({
					maxNumberAttempts: 2,
				}),
				cacheExchange,
				ssrExchange,
				multipartFetchExchange,
			],
		};
	},
);
