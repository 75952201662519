import {
	createContext,
	ReactNode,
	useState,
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
} from "react";
import { useQuery } from "urql";
import {
	CATEGORIES_PAGE,
	CATEGORIES_PAGE_ITEMS,
	RESOURCES_QUERY_ITEM,
	STATE_QUERY_PAGE,
	STATE_QUERY_PAGE_ITEMS,
} from "../../graphql/clientStrapio.query";
import { GET_STATIC_CONTEXT } from "./static-context.query";
import {
	Category_FragmentFragment,
	GetStaticContextQuery,
	GetStaticContextQueryVariables,
	Retailer_FragmentFragment,
	State_FragmentFragment,
	WhiteLabelConfig_FragmentFragment,
	VocabularyEntry_FragmentFragment,
} from "./static-context.query.graphql.module";

interface Props {
	children: ReactNode;
	host: string;
}

export interface IRegion {
	title: string;
	value: string;
}

interface StaticContext {
	// host
	host: string;
	// retailers
	retailers: Retailer_FragmentFragment[];
	retailerTranslator: (retailerId: string) => string;
	// vocabulary Entries
	vocabularyEntries: VocabularyEntry_FragmentFragment[];
	getVocabularySortedAlphabetically: () => VocabularyEntry_FragmentFragment[];
	getVocabularyEntry: (
		vocabularyEntryId: string | undefined,
	) => VocabularyEntry_FragmentFragment | undefined;
	translator: (vocabularyEntryId: string) => string;
	// categories
	categories: Category_FragmentFragment[];
	getCategory: (categoryId: string) => Category_FragmentFragment | undefined;
	getLinkedCategories: (categoryId: string) => Category_FragmentFragment[];
	getLinkedCategoriesByIds: (
		categoryIds: string[],
	) => Category_FragmentFragment[];
	getCategoriesByIds: (categoryIds: string[]) => Category_FragmentFragment[];
	getCategoryByKeyword: (
		categoryKeyword: string,
	) => Category_FragmentFragment | undefined;
	translateCategory: (categoryId: string) => string;
	categoryIsParent: (categoryId: string) => boolean;
	// states
	states: State_FragmentFragment[];
	regions: IRegion[];
	getState: (stateId: string) => State_FragmentFragment | undefined;
	getStateByAbbreviation: (
		stateId: string,
	) => State_FragmentFragment | undefined;
	// white label Config
	whiteLabelConfig: WhiteLabelConfig_FragmentFragment | null | undefined;

	// SearchFilterValue
	isHidden: boolean;
	setIsHidden: Dispatch<SetStateAction<boolean>>;
	//strpio
	statePageStrapio?: any;
	getStateStrapio?: any;
	statePageStrapioItems?: any;
	categoriesPageStrapio?: any;
	categoriesPageStrapioItems?: any;
	getCategoriesStrapio?: any;
	resoursePageStrapioItems?: any;
	resourcesPage?: any;
}

export const StaticContext = createContext<StaticContext>({} as any);

export default function StaticContextProvider({
	host,
	children,
}: Props): JSX.Element {
	const [staticContextResponse] = useQuery<
		GetStaticContextQuery,
		GetStaticContextQueryVariables
	>({
		requestPolicy: "cache-first",
		query: GET_STATIC_CONTEXT,
		pause: !host,
		variables: {
			domain: host,
		},
	});

	const [resultState] = useQuery({
		query: STATE_QUERY_PAGE,
		context: useMemo(
			() => ({
				requestPolicy: "cache-and-network",
				url: process.env.GRAPH_URL_STRAPIO,
				//url: "https://cms.staging.buylowwarehouse.com/graphql",
			}),
			[],
		),
	});

	const [resultStateItems] = useQuery({
		query: STATE_QUERY_PAGE_ITEMS,
		context: useMemo(
			() => ({
				requestPolicy: "cache-and-network",
				url: process.env.GRAPH_URL_STRAPIO,
				//url: "https://cms.staging.buylowwarehouse.com/graphql",
			}),
			[],
		),
	});

	const [resultCategoriesPage] = useQuery({
		query: CATEGORIES_PAGE,
		context: useMemo(
			() => ({
				requestPolicy: "cache-first",
				url: process.env.GRAPH_URL_STRAPIO,
				//url: "https://cms.staging.buylowwarehouse.com/graphql",
			}),
			[],
		),
	});

	const [resultCategoriesPageItems] = useQuery({
		query: CATEGORIES_PAGE_ITEMS,
		context: useMemo(
			() => ({
				requestPolicy: "cache-first",
				url: process.env.GRAPH_URL_STRAPIO,
				//url: "https://cms.staging.buylowwarehouse.com/graphql",
			}),
			[],
		),
	});

	const [resultResoursePage] = useQuery({
		query: RESOURCES_QUERY_ITEM,
		context: useMemo(
			() => ({
				requestPolicy: "cache-and-network",
				url: process.env.GRAPH_URL_STRAPIO,
				//url: "https://cms.staging.buylowwarehouse.com/graphql",
			}),
			[],
		),
	});

	const resoursePageStrapioItems =
		resultResoursePage?.data?.resourcesPageItems?.data || [];
	const resourcesPage = resultResoursePage?.data?.resourcesPage?.data || [];

	const categoriesPageStrapio =
		resultCategoriesPage?.data?.categoriesPage?.data || [];
	const categoriesPageStrapioItems =
		resultCategoriesPageItems?.data?.categoriesCollections?.data || [];

	categoriesPageStrapioItems.forEach((element: any) => {
		let result = element?.attributes?.category.split("_ID_");
		element.attributes.id = result[1].replace(/_/g, "-");
		element.attributes.categoryName = result[0].replace(/_/g, " ");
	});

	const getCategoriesStrapio = (id: string) => {
		let result = categoriesPageStrapioItems.find(
			(state: any) => state?.attributes?.id === id,
		);
		return result;
	};

	const statePageStrapio = resultState?.data?.statePage?.data?.attributes || [];
	const statePageStrapioItems =
		resultStateItems?.data?.stateCollections?.data || [];

	statePageStrapioItems.forEach((element: any) => {
		let result = element?.attributes?.state_ID.split("_ID_");
		element.attributes.id = result[1].replace(/_/g, "-");
		element.attributes.stateName = result[0].replace("_", " ");
	});

	const getStateStrapio = (stateID: string) => {
		let result = statePageStrapioItems.find(
			(state: any) => state?.attributes?.url === stateID,
		);
		return result;
	};

	const retailers = staticContextResponse.data?.findAllRetailers.data || [];
	const vocabularyEntries =
		staticContextResponse.data?.getVocabulary.data || [];
	const states = staticContextResponse.data?.findAllState.data || [];
	const categories = staticContextResponse.data?.getAllCategories.data || [];
	const whiteLabelConfig = staticContextResponse.data?.getConfigByDomain.data;

	const getLinkedCategories = (categoryId: string) => {
		const category = getCategory(categoryId);
		if (!category) {
			return [];
		}
		if (category.linkedCategories.length) {
			return category.linkedCategories
				.filter((linkedCatId) => {
					return getCategory(linkedCatId);
				})
				.map((linkedCatId) => {
					return getCategory(linkedCatId)!;
				});
		}
		return [category];
	};
	const getLinkedCategoriesByIds = (categoryIds: string[]) => {
		const categories = categoryIds
			.map((cat) => getLinkedCategories(cat))
			.flat();
		const orthodoxCategories = Array.from(
			new Set(categories.map((cat) => cat.id)),
		);
		return getCategoriesByIds(orthodoxCategories);
	};
	const getCategoriesByIds = (categoryIds: string[]) => {
		return categoryIds
			.filter((id) => {
				return getCategory(id);
			})
			.map((id) => {
				return getCategory(id)!;
			});
	};

	const getCategoryByKeyword = (categoryKeyword: string) => {
		return categories.find((category) => {
			return category.keyword === categoryKeyword;
		});
	};

	const getCategory = (categoryId: string) => {
		return categories.find((category) => {
			return category.id === categoryId;
		});
	};

	const [isHidden, setIsHidden] = useState(true);

	return (
		<StaticContext.Provider
			value={{
				host,
				isHidden,
				setIsHidden,
				//strapio
				//strapio-state
				statePageStrapio,
				statePageStrapioItems,
				getStateStrapio,
				//strapio-categories
				categoriesPageStrapio,
				categoriesPageStrapioItems,
				getCategoriesStrapio,
				//strapi-resourse
				resoursePageStrapioItems,
				resourcesPage,
				// retailers
				retailers: retailers.sort(function (a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				}),
				retailerTranslator: (retailerId: string) => {
					return (
						retailers.find((retailer) => {
							return retailer.id === retailerId;
						})?.name || retailerId
					);
				},
				//vocabulary entries
				vocabularyEntries: vocabularyEntries.sort(function (a, b) {
					if (a.index < b.index) {
						return -1;
					}
					if (a.index > b.index) {
						return 1;
					}
					return 0;
				}),
				getVocabularySortedAlphabetically: () => {
					return vocabularyEntries.sort(function (a, b) {
						if (a.title < b.title) {
							return -1;
						}
						if (a.title > b.title) {
							return 1;
						}
						return 0;
					});
				},
				getVocabularyEntry: (vocabularyEntryId: string | undefined) => {
					return vocabularyEntries.find((vocabularyEntry) => {
						return vocabularyEntry.id === vocabularyEntryId;
					});
				},
				translator: (vocabularyEntryId: string) => {
					return (
						vocabularyEntries.find((vocabularyEntry) => {
							return vocabularyEntry.id === vocabularyEntryId;
						})?.title || vocabularyEntryId
					);
				},
				// categories
				categories: categories.sort(function (a, b) {
					if (a.title < b.title) {
						return -1;
					}
					if (a.title > b.title) {
						return 1;
					}
					return 0;
				}),
				getCategory: getCategory,
				getCategoryByKeyword: getCategoryByKeyword,
				getLinkedCategories,
				getLinkedCategoriesByIds,
				getCategoriesByIds,
				translateCategory: (categoryId: string) => {
					return (
						categories.find((category) => {
							return category.id === categoryId;
						})?.title || categoryId
					);
				},
				categoryIsParent: (categoryId: string) => {
					const rootCategory = getCategoryByKeyword("CATEGORY");
					const currentCategory = getCategory(categoryId);
					if (!currentCategory || !rootCategory) {
						return false;
					}
					if (rootCategory.id != currentCategory.parent?.id) {
						return false;
					}
					return true;
				},
				// states
				states: states.sort(function (a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				}),
				regions:
					states
						.filter((state, pos, statesArray) => {
							return (
								statesArray.findIndex((s) => {
									return s.region === state.region;
								}) == pos
							);
						})
						.map((state) => {
							return {
								value: state.region,
								title: state.region,
							};
						}) || [],
				getState: (stateId: string) => {
					return states.find((state) => {
						return state.id == stateId;
					});
				},
				getStateByAbbreviation: (abbreviation: string) => {
					return states.find((state) => {
						return state.abbreviation == abbreviation;
					});
				},
				whiteLabelConfig: whiteLabelConfig,
			}}
		>
			{children}
		</StaticContext.Provider>
	);
}
